import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import ProTip from './ProTip';
import ePub from 'epubjs';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function App() {
  const sampleEpubUrl = "/travel_tw_2023.epub"; // Replace with your EPUB file path
  const book = ePub(sampleEpubUrl);
  const [rendition, setRendition] = useState(null);
  const epubRef = useRef(null); // <-- Ref to bind the EPUB content

  useEffect(() => {
    if (epubRef.current) {
      setRendition(book.renderTo(epubRef.current, { width: "100%", height: "600px" }));
    }
  }, [book, epubRef]);

  useEffect(() => {
    if (rendition) {
      rendition.display(); // Show the EPUB content
    }
  }, [rendition]);

  // Navigate to the next page
  const nextPage = () => {
    if (rendition) {
      rendition.next();
    }
  };

  // Navigate to the previous page
  const prevPage = () => {
    if (rendition) {
      rendition.prev();
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Read book together
        </Typography>
        <div ref={epubRef} style={{ height: "600px", border: "1px solid black" }}></div>
        <Box sx={{ my: 2, display: 'flex', justifyContent: 'space-between' }}> {/* <-- Container for navigation buttons */}
          <Button onClick={prevPage} variant="contained" color="primary">Previous</Button>
          <Button onClick={nextPage} variant="contained" color="primary">Next</Button>
        </Box>
        <ProTip />
        <Copyright />
      </Box>
    </Container>
  );
}